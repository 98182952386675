<template>
  <div>
    <v-layout pt-12></v-layout>
    <v-layout pt-12></v-layout>
    <v-layout wrap pt-12 justify-center>
      <v-flex xs12 md2 align="center">
        <v-img src="../../assets/icons/tick.png" aspect-ratio="1" contain height="50px"></v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 text-center>
        <span style="font-size:18px;color:#000;font-weight:400">Session Expired</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
        <v-flex xs12 md12 pt-3 text-center>
          <router-link to="/Login">
            <v-btn
              rounded
              width="200px"
              :ripple="false"
              class="text-capitalize"
              style="font-weight:300;"
              color="#26af82"
              dark
            >Login to Continue</v-btn>
          </router-link>
        </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md3 align="center">
        <v-img src="../../assets/person.png" contain height="300px"></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  beforeMount() {
    this.submit();
  },
  methods: {
    submit() {
      setTimeout(() => {
        this.$router.push("/Login");
      }, 10000);
    }
  }
};
</script>